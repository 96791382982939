import React from 'react';

import AdminHeader from '../../components/admheader';
import AdminProducts from '../../components/admProducts';

const AdminEdit = () => {
  return (
    <>
      <header>
        <AdminHeader />
      </header>
      <main>
        <AdminProducts />
      </main>
    </>
  );
}

export default AdminEdit;