import React, { Component } from "react";
import Carousel from "react-simply-carousel";

import './SimplyCarousel.css';
import slideImg11 from '../../../assets/img/slide/slide/slide1.png';
import slideImg12 from '../../../assets/img/slide/slide/slide2.png';
import slideImg21 from '../../../assets/img/slide/slide2/slide1.png';
import slideImg22 from '../../../assets/img/slide/slide2/slide2.png';
import slideImg31 from '../../../assets/img/slide/slide3/slide1.png';
import slideImg32 from '../../../assets/img/slide/slide3/slide2.png';
import slideImg41 from '../../../assets/img/slide/slide4/slide1.png';
import slideImg42 from '../../../assets/img/slide/slide4/slide2.png';
import slideImg51 from '../../../assets/img/slide/slide5/slide1.png';
import slideImg52 from '../../../assets/img/slide/slide5/slide2.png';
import slideImg61 from '../../../assets/img/slide/slide6/slide1.png';
import slideImg62 from '../../../assets/img/slide/slide6/slide2.png';

class SimplyCarousel extends Component {
  state = {
    activeSlideIndex: 0,
    classCarousel: "carousel carousel--opacity"
  };

  setActiveSlideIndex = (newActiveSlideIndex) => {

    this.setState({
      activeSlideIndex: newActiveSlideIndex,
    });
    if (newActiveSlideIndex < 3) {
      setTimeout(() => {
        this.setState({
          classCarousel: "carousel carousel--opacity"
        });
      }, 2300);
    } else {
      setTimeout(() => {
        this.setState({
          classCarousel: "carousel"
        });
      }, 2300);
    }
  };

  render() {
    return (
      <div className="carousel--gamer">
        <div className={this.state.classCarousel}>
          <Carousel
            activeSlideIndex={this.state.activeSlideIndex}
            onRequestChange={this.setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}
            autoplay={true}
            delay={2000}
            speed={300}
            forwardBtnProps={{ style: { display: "none" } }}
            backwardBtnProps={{ style: { display: "none" } }}

          >
            <div className="carousel__slide">
              <img src={slideImg11} />
              <img src={slideImg12} />
            </div>
            <div className="carousel__slide">
              <img src={slideImg21} />
              <img src={slideImg22} />
            </div>
            <div className="carousel__slide">
              <img src={slideImg31} />
              <img src={slideImg32} />
            </div>
            <div className="carousel__slide">
              <img src={slideImg41} />
              <img src={slideImg42} />
            </div>
            <div className="carousel__slide">
              <img src={slideImg51} />
              <img src={slideImg52} />
            </div>
            <div className="carousel__slide">
              <img src={slideImg61} />
              <img src={slideImg62} />
            </div>
          </Carousel>
        </div>
      </div>
    );
  }
}

export default SimplyCarousel;