import React from 'react';
import Routes from '../components/Routes';


import './Reset.css';
import './Variables.css';
import './Global.css'


const App = () => {
  return (

        <Routes />

  );
}

export default App;
