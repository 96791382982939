import React, { useState, useEffect } from 'react';
import api from '../../services';
import { history } from '../../../history';

import offerImg from '../../../assets/img/offer.png';
import editIcon from '../../../assets/img/icon/edit.png';
import deleteIcon from '../../../assets/img/icon/delete.png';
import './CardEdit.css';

const CardEdit = props => {

  const [offer, setOffer] = useState('');
  const [price, setPrice] = useState(<><br /><br /></>);

  useEffect(() => {
    if (props.offer === "true") {
      setOffer(<span className="card__offer"><img src={offerImg} /></span>)
    }

    if (props.price !== null) {
      setPrice(
        <p className="card__price">R$ {props.price}
          <span className="card__span"> à vista</span>
        </p>)
    }
  }, [])

  const handleDelete = (id, name) => {
    const delet = window.confirm("Tem certeza que deseja deletar " + name + "?");
    if (delet == true) {
      const value = {
        appToken: sessionStorage.getItem('app-token')
      }
      api.post('/api/delet-prod/product/' + id, value)
        .then(() => {
            alert('Produto deletado com sucesso!')
            history.go();
        })
    } else {
      alert("Cancelado!");
    }
  }

  return (
    <div className="card">
      <span className="card__delet"><a onClick={() => handleDelete(props.id, props.name)}>
        <img className="icon" src={deleteIcon} />
      </a></span>
      <span className="card__edit"><a href={"/admin/edit/" + props.id}>
        <img className="icon" src={editIcon} />
      </a></span>
      <div className="card__img">
        <img className="card__img--img"
          src={process.env.PUBLIC_URL + '/uploads/' + props.photo}
          alt="Produto" />
      </div>
      {offer}
      {price}
      <h1 className="card__product">{props.name}</h1>
      <p className="card__brand">{props.brand}</p>
      <p className="card__description">{props.description}</p>
    </div>
  );
}

export default CardEdit;