import React, { Component } from "react";
import { ErrorMessage, Formik, Form, Field } from "formik";
import * as yup from "yup";
import api from "../../components/services";

import AdmHeader from "../../components/admheader";
import AdmProducts from "../../components/admProducts";
import { history } from "../../history";

import noImage from "../../assets/img/sem-foto.jpg";

import "./Admin.css";

class Admin extends Component {
  state = {
    profileImg: noImage,
  };

  imageHandler = (e, formProps) => {
    formProps.setFieldValue("photo", e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ profileImg: reader.result });
      }
    };
    if (e.target.files[0] != undefined) {
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({ profileImg: noImage });
  };

  handleSubmit = (values) => {
    if (values.offer != undefined) {
      if (values.offer.length > 0) {
        values.offer = "true";
      } else {
        values.offer = "false";
      }
    } else {
      values.offer = "false";
    }
    var bodyFormData = new FormData();
    bodyFormData.append("photo", values.photo);
    bodyFormData.append("name", values.name);
    bodyFormData.append("price", values.price);
    bodyFormData.append(
      "description",
      values.description.replace(/\n/g, "<br>")
    );
    bodyFormData.append("type", values.type);
    bodyFormData.append("product", values.product);
    bodyFormData.append("brand", values.brand);
    bodyFormData.append("offer", values.offer);
    bodyFormData.append("appToken", sessionStorage.getItem("app-token"));

    if (values.price === undefined || values.price === "") {
      values.price = null;
    }

    api.post("/api/create-prod/product/", bodyFormData).then(() => {
      alert("Produto Cadastrado com sucesso!");
      history.go();
    });
  };

  render() {
    const validations = yup.object().shape({
      photo: yup.string().required("Tipo é obrigatório"),
      name: yup.string().required("Nome é obrigatório"),
      price: yup
        .number()
        .transform((_, value) => {
          if (value.includes(".")) {
            var number = value.replace(/\./g, 0);
            return +number.replace(/,/, ".");
          }
          return +value.replace(/,/, ".");
        })
        .positive(),
      description: yup.string().required("Descrição é obrigatório"),
      type: yup.string().required("Categoria é obrigatório"),
      product: yup.string().required("Produto é obrigatório"),
      brand: yup.string().required("Marca é obrigatório"),
    });

    return (
      <>
        <header>
          <AdmHeader />
        </header>
        <main>
          <div className="admin">
            <h1 className="subtitle">
              {sessionStorage.getItem("name")}, seja bem-vindo(a)!
            </h1>
            <h2 className="title">Cadastrar Novo Produto</h2>
            <div className="card__img admin__group__img">
              <img
                src={this.state.profileImg}
                alt=""
                id="img"
                className="card__img--img"
              />
            </div>

            <Formik
              initialValues={{}}
              onSubmit={this.handleSubmit}
              validationSchema={validations}
            >
              {(formProps) => (
                <Form>
                  <label className="admin__group">
                    <Field
                      name="photo"
                      className="admin__group__field"
                      type="file"
                      accept="image/*"
                      name="image-upload"
                      id="input"
                      onChange={(e) => this.imageHandler(e, formProps)}
                    />
                  </label>
                  <label className="admin__group">
                    <Field
                      name="name"
                      className="admin__group__field"
                      placeholder="Nome"
                    />
                    <ErrorMessage
                      component="span"
                      name="name"
                      className="admin__group__error"
                    ></ErrorMessage>
                  </label>
                  <label className="admin__group">
                    <Field
                      name="price"
                      className="admin__group__field admin__group__field--price"
                      placeholder="Preço"
                    />
                    <ErrorMessage
                      component="span"
                      name="price"
                      className="admin__group__error"
                    ></ErrorMessage>
                  </label>
                  <label className="admin__group">
                    <Field
                      name="description"
                      className="admin__group__field admin__group__field--textarea"
                      placeholder="Descrição"
                      as="textarea"
                    />
                    <ErrorMessage
                      component="span"
                      name="description"
                      className="admin__group__error"
                    ></ErrorMessage>
                  </label>
                  <label className="admin__group">
                    <Field
                      as="select"
                      name="type"
                      className="admin__group__field admin__group__field--select"
                    >
                      <option value="">Categoria</option>
                      <option value="Escritório">Escritório</option>
                      <option value="Informática">Informática</option>
                      <option value="Diversos">Diversos</option>
                    </Field>
                    <ErrorMessage
                      component="span"
                      name="type"
                      className="admin__group__error"
                    ></ErrorMessage>
                  </label>
                  <label className="admin__group">
                    <Field
                      name="product"
                      className="admin__group__field"
                      placeholder="Produto"
                    />
                    <ErrorMessage
                      component="span"
                      name="product"
                      className="admin__group__error"
                    ></ErrorMessage>
                  </label>
                  <label className="admin__group">
                    <Field
                      name="brand"
                      className="admin__group__field"
                      placeholder="Marca"
                    />
                    <ErrorMessage
                      component="span"
                      name="brand"
                      className="admin__group__error"
                    ></ErrorMessage>
                  </label>
                  <label className="admin__checkbox">
                    <Field
                      type="checkbox"
                      name="offer"
                      value="True"
                      className="admin__check"
                    />
                    <span className="admin__checkmark"></span>&nbsp; Oferta
                  </label>
                  <button className="admin__btn" type="submit">
                    Cadastrar
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <h1 className="title">Produtos Cadastrados</h1>
          <AdmProducts />
        </main>
      </>
    );
  }
}

export default Admin;
