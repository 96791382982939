import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';

import { history } from '../../history';

import './Header.css'
import logo from '../../assets/img/logo.png';

class Header extends Component {
  state = {
    class: 'header__nav',
    active: false,
    classPopup: 'dropdown-content',
    activePopup: false
  }


  toggleMenu = () => {
    const currentState = this.state.active;
    if (currentState) {
      this.setState({
        class: 'header__nav',
        active: false,
      });
      return;
    }
    this.setState({
      class: 'header__nav header__nav--open',
      active: true,
    });
  }

  togglePopup = () => {
    const currentState = this.state.activePopup;
    if (currentState) {
      this.setState({
        classPopup: 'dropdown-content',
        activePopup: false,
      });
      return;
    }
    this.setState({
      classPopup: 'dropdown-content dropdown-content--show',
      activePopup: true,
    });
  }

  handleSubmit = values => {
    history.push('/produtos?search=' + values.search.replace(/\s/g, "%20"));
    history.go();
  }

  render() {
    return (
      <div className="header">
        <h1 className="header__logo">
          <a href="/">
            <img src={logo} alt="Logo" className="header__logo__img" />
          </a>
        </h1>
        <div className="header__menu">
          <Formik
            initialValues={{}}
            onSubmit={this.handleSubmit}
          >
            <Form>
              <Field
                name="search"
                className="header__search"
                placeholder="Pesquisa"
              />
            </Form>
          </Formik>

          <a className="header__btn" onClick={this.toggleMenu}></a>

          <nav className={this.state.class}>
            <ul className="header__list">
              <a className="header__list__link" href="/">
                <li className="header__list__item">
                  HOME
                </li>
              </a>

              <a className="header__list__link dropdown"
                onMouseOver={this.togglePopup}
                onMouseOut={this.togglePopup}>
                <li className="header__list__item">
                  PRODUTOS
                  <div className={this.state.classPopup}>
                    <a href="/produtos?type=Informática">INFORMÁTICA</a>
                    <a href="/produtos?type=Escritório">ESCRITÓRIO</a>
                    <a href="/produtos?type=Diversos">DIVERSOS</a>
                  </div>
                </li>
              </a>

              <a className="header__list__link" href="/servicos">
                <li className="header__list__item">
                  SERVIÇOS
                </li>
              </a>

              <a className="header__list__link" href="https://tektelecom.com.br/" target="_blank">
                <li className="header__list__item">
                  INTERNET
                </li>
              </a>

              <a className="header__list__link" href="/sobre">
                <li className="header__list__item">
                  SOBRE
                </li>
              </a>

              <a className="header__list__link header__list__link--last" href="#contato">
                <li className="header__list__item">
                  CONTATO
                </li>
              </a>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default Header;