import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Address from '../../components/address';

import './About.css';
import loja from '../../assets/img/loja.jpg';

const Services = () => {
  return (
    <>
      <header>
        <Header />
      </header>
      <main className="main">
        <section>
          <h1 className="title">Sobre nós&nbsp;...</h1>
          <article className="about">
          <img src={loja} className="about__img" />
            <p className="about__paragraph">
              Fundada em 1998 em Catalão Goiás, a Tek Informatica ocupa posição de destaque entre as lojas especializadas em informática no Sudeste Goiano. Com 1.500 m² de área útil, a sede da Tek Informatica abriga diversos serviços que visam o melhor atendimento ao público. Entre eles estão o enorme showroom com produtos das melhores marcas, completa linha de moveis para escritorio.
            </p>

            <p className="about__paragraph">
              Para atender às expectativas de consumo dos clientes, abrangendo tanto os leigos como os mais exigentes, a Tek Informatica dispõe de uma equipe de especialistas de alta qualificação. A excelência na prestação de serviços garante um atendimento diferenciado e personalizado, tanto no setor de vendas quanto na assistência técnica. Além do mercado varejista, a Tek Informatica atua na assistencia autorizada Bematech e automação de controle de estoque, oferecendo para pequenas, médias e grandes empresas soluções compatíveis com a necessidade de cada tipo de negócio.
            </p>
            <h2 className="about__subtitle">
              Temos como principal meta a satisfação do nosso cliente.
            </h2>
            <p className="about__paragraph">
              A Tek Informática acredita que o principal serviço começa no bom atendimento e trabalho realizado com eficiencia. Acreditamos que o nosso crescimento contínuo depende exclusivamente da nossa constante perseguição em melhorar nossa eficiência e excelência em atendimento. Buscamos sempre ultrapassar as suas expectativas todas as vezes em que você compra na Tek Informatica.
            </p>
            <h2 className="about__subtitle">
              Variedade e produtos de qualidade
            </h2>
            <p className="about__paragraph">
              Através de parcerias com os maiores distribuidores de tecnologia do Brasil, oferecemos aos nossos clientes grande variedade em marcas e produtos de qualidade. Com mais de 1.000 produtos diferentes em pronta entrega, garante-se a entrega dos pedidos dentro dos prazos estipulados. Trabalhamos somente com marcas e produtos de qualidade comprovada. Elas devem possuir representações legais no Brasil, através de um distribuidor oficial ou de um representante local que garanta a procedência dos produtos comercializados.
            </p>
            <p className="about__paragraph">
              Confiança, credibilidade, qualidade e agilidade: esses são os nossos principais objetivos.
            </p>
          </article>
          
          <Address />
        </section>
      </main>

      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default Services;