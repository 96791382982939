import React, { Component } from 'react';
import SimplyCarousel from './simply-carousel';

import Header from '../../components/header';
import Footer from '../../components/footer';

import Offers from '../../components/offers';
import Budget from '../../components/budget';
import Address from '../../components/address';

import brandsImg from '../../assets/img/marcas.png';

import './Home.css';
import loja from '../../assets/img/loja.jpg';

import instagramPerfil from '../../assets/img/instagram.png';

class Home extends Component {
  state = {}
  render() {
    return (
      <>
        <header>
          <Header />
        </header>

        <main className="main">
          <div className="home">
            <SimplyCarousel />
            <div className="home__brands--img">
              <img className="home__brands" src={brandsImg} />
            </div>
            <Offers />

           {/*  <section>
              <h1 className="title">Sobre nós&nbsp;...</h1>
              <h2 className="about__subtitle about__subtitle--home">A LOJA DE INFORMÁTICA MAIS COMPLETA DA CIDADE.</h2>
              <article className="about">
                <img src={loja} className="about__img" />
                <p className="about__paragraph">
                  Fundada em 1998 em Catalão Goiás, a Tek Informatica ocupa posição de destaque entre as lojas especializadas em informática no Sudeste Goiano. Com 1.500 m² de área útil, a sede da Tek Informatica abriga diversos serviços que visam o melhor atendimento ao público. Entre eles estão o enorme showroom com produtos das melhores marcas, completa linha de moveis para escritorio.
                </p>

                <p className="about__paragraph">
                  Para atender às expectativas de consumo dos clientes, abrangendo tanto os leigos como os mais exigentes, a Tek Informatica dispõe de uma equipe de especialistas de alta qualificação. A excelência na prestação de serviços garante um atendimento diferenciado e personalizado, tanto no setor de vendas quanto na assistência técnica. Além do mercado varejista, a Tek Informatica atua na assistencia autorizada Bematech e automação de controle de estoque, oferecendo para pequenas, médias e grandes empresas soluções compatíveis com a necessidade de cada tipo de negócio.
                </p>
              </article>
              <a href="/sobre" className="offers__link">Ler mais...</a>
            </section> */}
            <section className="home__instagram">
              <h2 className="title" >Siga-nos no Instagram&nbsp;...</h2>
              <a href="https://www.instagram.com/tek.informatica/" target="_blank">
                <img src={instagramPerfil} className="home__instagram--perfil" />
              </a>
            </section>

{/*             <section className="center  home__tektelecom">
              <h1 className="title">Internet ...</h1>
              <h2 className="home__tektelecom--subtitle">Contrate Internet Com A Qualidade</h2>
              <a className="button" href="https://tektelecom.com.br" target="_blank" />
              <p className="home__tektelecom--text">Internet Fibra Óptica e Rádio de alta qualidade e baixo custo.</p>
              <p className="home__tektelecom--text">Garantimos a velocidade da sua internet na fibra óptica e via rádio.</p>
              <p className="home__tektelecom--text">Temos planos para todo tipo de consumo, sem limites e sem franquias.</p>
            </section> */}

            <Budget />
            <Address />
          </ div>
        </main>
        <footer>
          <Footer />
        </footer>
      </>
    );
  }
}

export default Home;