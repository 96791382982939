import React from 'react';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Address from '../../components/address';
import Budget from '../../components/budget';

import './Services.css';

import computadores from '../../assets/img/computadores.jpg';
import moveis from '../../assets/img/moveis.jpg';
import manutencao from '../../assets/img/manutencao.jpg';
import bematech from '../../assets/img/bematech.png';
import certificado from '../../assets/img/certificado-digital.png';

const Services = () => {
  return (
    <>
      <header>
        <Header />
      </header>

      <main className="main">
        <section>
          <h1 className="title">Serviços&nbsp;...</h1>
          <div className="services">
            
            <article className="item__text--1">
              <h2 className="services__subtitle">Venda de computadores</h2>
              <p className="services__paragraph">A Tek Informática é especializada em venda de computadores, Notebooks, placas, acessórios, periféricos e outros componentes de informática. Com vendedores expecializados para o melhor atendimento e orientação sobre os hardwares adequados para sua necessidade.</p>
            </article>
            <img src={computadores} alt="Móveis de escritório" className="services__img item__img--1" />

            <img src={moveis} alt="Móveis de escritório" className="services__img item__img--2" />
            <article className="item__text--2">
              <h2 className="services__subtitle">Venda de móveis para escritório</h2>
              <p className="services__paragraph">A Tek Informática é especializada em venda de móveis para escritorios, mesas, cadeiras, armários, entre outros. A empresa conta com uma equipe de profissionais qualificados e aptos a entender cada consumidor e suas necessidades.</p>
            </article>

            <article className="item__text--3">
              <h2 className="services__subtitle">Assistência técnica de computadores</h2>
              <p className="services__paragraph">Especializados em assistência técnica de computadores, notebook, impressoras, redes, entre outros. A Tek Informática tem foco total na satisfação de seus clientes, isto resulta em serviços de alta qualidade, com a garantia de uma empresa com mais de 23 anos de tradição no mercado de assistência técnica de computadores e venda de periféricos em Catalão.</p>
            </article>
            <img src={manutencao} alt="Assistência técnica de computadores" className="services__img item__img--3" />

            <img src={bematech} alt="Autorizada Bematech" className="services__img item__img--4" />
            <article className="item__text--4">
              <h2 className="services__subtitle">Autorizada Bematech</h2>
              <p className="services__paragraph">Disponibilizamos aos clientes a prestação de serviços técnicos e especializados para todos os equipamentos de automação comercial fabricados pela Bematech.</p>
            </article>

            <article className="item__text--5">
              <h2 className="services__subtitle">Emissão Certificado Digital</h2>
              <p className="services__paragraph">O certificado digital serve para assinar digitalmente documentos e ter acesso a sistemas eletrônicos restritos, principalmente de órgãos públicos na internet, como Receita Federal e INSS. Também comprova a identidade em sistemas virtuais integrados para realizar atividades profissionais de várias categorias.</p>
            </article>
            <img src={certificado} alt="Autorizada Bematech" className="services__img item__img--5" />
          </div>
          <Budget />
          <Address />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default Services;