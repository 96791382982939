import React, { useState, useEffect } from 'react';

import './Card.css'
import offerImg from '../../assets/img/offer.png';

const Card = (props) => {

  const [offer, setOffer] = useState('');
  const [price, setPrice] = useState(<><br /><br /></>);

  useEffect(() => {
    if (props.offer === "true") {
      setOffer(<span className="card__offer"><img src={offerImg} alt="Offer" /></span>)
    }

    if (props.price !== null) {
      setPrice(
        <p className="card__price">R$ {props.price}
          <span className="card__span"> à vista</span>
        </p>)
    }
  }, [])

  return (
    <a className="card" href={"/produto/" + props.id}>
      <div className="card__img">
        <img className="card__img--img"
          src={process.env.PUBLIC_URL + '/uploads/' + props.photo}
          alt="Produto" />
      </div>
      {offer}
      {price}
      <h1 className="card__product">{props.name}</h1>
      <p className="card__brand">{props.brand}</p>
      <p className="card__description">{props.description}</p>
    </a>
  );
}

export default Card;