import React from 'react';

import instagram from '../../assets/img/icon/instagram-red.png';
import whatsapp from '../../assets/img/icon/whatsapp-red.png';
import facebook from '../../assets/img/icon/facebook-red.png';

import qr from '../../assets/img/qr.jpg';

import './Address.css';

const Address = () => {
  return (
    <section id="contato">
      <h2 className="title">Entre em contato&nbsp;...</h2>
      <div className="address">
    <img src={qr} className="address--item address--qr"/>
    <p className="address--item address--title">Redes Sociais</p>
    <p className="address--item">
        <a className="icon" href="https://api.whatsapp.com/send?phone=556434113333">
            <img src={whatsapp} alt="Logo Whatsapp"/>
        </a>
        <a className="icon" href="https://www.instagram.com/tektelecom/">
            <img src={instagram} alt="Logo Instagram"/>
        </a>
        <a className="icon" href="https://www.facebook.com/Tek-Telecom-333843574148661/">
            <img src={facebook} alt="Logo Facebook"/>
        </a>
    </p>
    <p className="address--item"><a href="tel:(64) 3411-4552">(64) 3411-4552</a></p>
    <p className="address--item"><a href="tel:(64) 98438-9596">(64) 98438-9596</a></p>
    <p className="address--item"><a href="mailto:vendastek@hotmail.com">vendastek@hotmail.com</a></p>
    <p className="address--item"><a href="mailto:clesia_amx@hotmail.com">clesia_amx@hotmail.com</a></p>
    <p className="address--item"><a href="mailto:marcio_mm@hotmail.com">marcio_mm@hotmail.com</a></p>
    <p className="address--item">R. Nassim Agel, 287 - St. Central, Catalão - GO</p>
    <iframe className="address--item address--map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15163.408017194504!2d-47.9468221!3d-18.1707303!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x424f60a0f96bd633!2sTek%20Inform%C3%A1tica!5e0!3m2!1spt-BR!2sbr!4v1625620170066!5m2!1spt-BR!2sbr"
        frameBorder="0"
        title="Mapa"></iframe>
</div>
    </section>
  );
}

export default Address;