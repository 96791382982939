import React from 'react'
import { Router, Switch, Route } from 'react-router';

import Home from './../pages/home';
import Services from './../pages/services';
import About from './../pages/about';
import Admin from '../pages/admin';
import AdminEdit from '../pages/adminEdit';
import Login from '../pages/login';
import Edit from '../pages/edit';
import Products from '../pages/products';
import Product from '../pages/product';
import Register from '../pages/register';
import NotFound from './NotFound';
import PrivateRoute from './PrivateRoute';

import { history } from '../history';


const Routes = () => {
  return (
    <Router history={history} >
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Services} exact path="/servicos" />
        <Route component={About} exact path="/sobre" />
        <Route component={Products} exact path="/produtos" />
        <Route component={Product} exact path="/produto/:id" />
        <Route component={Login} exact path="/login" />
        <Route component={Register} exact path="/register" />
        <PrivateRoute component={Admin} exact path="/admin" />
        <PrivateRoute component={Edit} exact path="/admin/edit/:id" />
        <PrivateRoute component={AdminEdit} exact path="/admin/edit" />
        <NotFound/>
      </Switch>
    </Router>
  );
}

export default Routes;