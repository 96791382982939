import React, { Component } from 'react';
import { history } from '../../history';

import './AdmHeader.css';
import logo from '../../assets/img/logo.png';

class AdmHeader extends Component {
  state = {
    class: 'adm-header__nav',
    active: false,
  }


  toggleMenu = () => {
    const currentState = this.state.active;
    if (currentState) {
      this.setState({
        class: 'adm-header__nav',
        active: false,
      });
      return;
    }
    this.setState({
      class: 'adm-header__nav adm-header__nav--open',
      active: true,
    });
  }

  handleClick = () => {
    history.push('/login');
    sessionStorage.removeItem('app-token');
    sessionStorage.removeItem('name');
  }

  render() {
    return (
      <div className="adm-header">
        <h1 className="adm-header__logo">
          <a href="/admin">
            <img src={logo} alt="Logo" className="adm-header__logo__img" />
          </a>
        </h1>
        <div className="adm-header__menu">
          <a className="adm-header__btn" onClick={this.toggleMenu}></a>

          <nav className={this.state.class}>
            <ul className="adm-header__list">
              <a className="adm-header__list__link" href="/admin">
                <li className="adm-header__list__item">
                  CADASTRAR
                </li>
              </a>

              <a className="adm-header__list__link" href="/admin/edit">
                <li className="adm-header__list__item">
                  EDITAR
                </li>
              </a>

              <a className="adm-header__list__link" href="#" onClick={this.handleClick}>
                <li className="adm-header__list__item">
                  DESCONECTAR
                </li>
              </a>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default AdmHeader;