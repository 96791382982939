import React, { useEffect, useState } from 'react';
import api from '../services';
import Card from '../Card';

import './Offers.css';

const Offers = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    api.get('/v1/api/product?offer=true').then(response => {
      setApiData(response.data);
    });
  }, [])

  return (

    <section>
      <h1 className="title">Ofertas&nbsp;... </h1>
      <div className="offers">
        {apiData.map(product => (
          <Card
            key={product.id}
            id={product.id}
            offer={product.offer}
            photo={product.photo}
            name={product.name}
            price={product.price}
            brand={product.brand} />
        ))}
      </div>
      <a className="offers__link" href="/produtos">Ver mais...</a>
    </section>
  );
}

export default Offers;