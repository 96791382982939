import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';

import App from './containers/App';

import 'normalize.css';
import './index.css';

ReactDOM.render(
  <HttpsRedirect>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HttpsRedirect>,
  document.getElementById('root')
);