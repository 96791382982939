import React, { useEffect, useState } from 'react';
import api from '../services';
import CardEdit from './cardEdit';

const AdmProducts = () => {
  const [apiData, setApiData] = useState([]);

  useEffect(() => {
    api.get('/v1/api/product').then(response => {
      setApiData(response.data);
    });
  }, [])

  return (

    <section>
      <div className="offers">
        {apiData.map(product => (
          <CardEdit
          id={product.id}
          offer={product.offer}
          photo={product.photo}
          name={product.name}
          price={product.price}
          brand={product.brand} />
        ))}
      </div>
    </section>
  );
}

export default AdmProducts;