import React, { useEffect, useState } from 'react';
import api from '../../components/services';
import Card from '../../components/Card';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Budget from '../../components/budget';
import Address from '../../components/address';

import './Products.css';

const Products = props => {

  const [classActive, setClassActive] = useState('products__menu products__menu--open');
  const [classClose, setClassClose] = useState('products__menu products__menu--close');
  const [classFilter, setFilter] = useState('products__nav products__nav--close');

  const [apiData, setApiData] = useState([]);

  const [type, setType] = useState([]);
  const types = [];

  const [product, setProduct] = useState([]);
  const products = [];

  const [brand, setBrand] = useState([]);
  const brands = [];
  const brandsOrg = [];

  const [getResults, setResults] = useState('');

  const search = props.location.search;
  var results = search.split("=");

  var getter = '';
  if (search === undefined) {
    search = '';
    getter = '/v1/api/product';
  } else {
    getter = '/v1/api/product' + search;
  }

  useEffect(() => {
    api.get(getter).then(response => {
      setApiData(response.data);
      const type = response.data.map(type => {
        setType(oldArray => [...oldArray, type.type]);
      })
      const product = response.data.map(product => {
        setProduct(oldArray => [...oldArray, product.product]);
      })
      const brand = response.data.map(brand => {
        setBrand(oldArray => [...oldArray, brand.brand]);

      })
      if (results[0] != "") {
        results = results[1].split("&");
        if (results[0] != "") {
          return setResults(<p className="products__results">Resultados para <strong>{decodeURI(results[0])}</strong>... Resultado para&nbsp;<a className="products__results products__results--link" href='/produtos'>todos os produtos.</a></p>)
        }
        return setResults(<p className="products__results">Resultados para <strong>{decodeURI(results[0])}</strong>... Resultado para&nbsp;<a className="products__results products__results--link" href='/produtos'>todos os produtos.</a></p>)
      }

    });
  }, [])

  const filterPlus = () => {
    setClassActive('products__menu products__menu--close')
    setClassClose('products__menu products__menu--open')
    setFilter('products__nav')
  }

  const filterLess = () => {
    setClassClose('products__menu products__menu--close')
    setClassActive('products__menu products__menu--open')
    setFilter('products__nav products__nav--close')
  }

  return (
    <>
      <header>
        <Header />
      </header>
      <main className="main">
        <section>
          <h1 className="title">Nossos Produtos&nbsp;... </h1>
          <section className="products__section">
            <nav className={classFilter}>
              <div className="products__filter">
                <h2 className="products__title">Categoria</h2>
                <ul>
                  {type.map((type, i) => {

                    if (types.indexOf(type) > -1) {
                      return
                    } if (search === '') {
                      types.push(type);
                      return <li><a href={'?type=' + type}>{type}</a></li>
                    } else {
                      types.push(type);
                      return <li><a href={search + '&type=' + type}>{type}</a></li>
                    }
                  })}
                </ul>
              </div>
              <div className="products__filter">
                <h2 className="products__title">Produto</h2>
                <ul>
                  {product.map((product, i) => {

                    if (products.indexOf(product) > -1) {
                      return
                    } else {
                      products.push(product);
                    }
                  })}

                  {products.sort().map((product, i) => {
                    if (search === '') {
                      return <li><a href={'?product=' + product}>{product}</a></li>
                    } else {
                      return <li><a href={search + '&product=' + product}>{product}</a></li>
                    }
                  })}
                </ul>
              </div>
              <div className="products__filter">
                <h2 className="products__title">Marca</h2>
                <ul>
                  {brand.map((brand, i) => {

                    if (brands.indexOf(brand) > -1) {
                      return
                    } else {
                      brands.push(brand);
                    }
                  })}
                  {brands.sort().map((brand, i) => {
                    if (search === '') {
                      return <li><a href={'?brand=' + brand}>{brand}</a></li>
                    } else {
                      return <li><a href={search + '&brand=' + brand}>{brand}</a></li>
                    }
                  })}
                </ul>
              </div>
            </nav>
            <a className={classActive} onClick={filterPlus}>
              Mostrar mais&nbsp;....</a>
            <a className={classClose} onClick={filterLess}>
              Mostrar menos&nbsp;....</a>
          </section>
          {getResults}
          <div className="offers">
            {apiData.map(product => (
              <Card
                id={product.id}
                offer={product.offer}
                photo={product.photo}
                name={product.name}
                price={product.price}
                brand={product.brand} />
            ))}
          </div>
        </section>
        <Budget />
        <Address />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default Products;