import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import InnerImageZoom from 'react-inner-image-zoom';
import api from '../../components/services';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Budget from '../../components/budget';
import Address from '../../components/address';
import Offers from '../../components/offers';

//import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import './Product.css';

const Product = props => {

  const [apiData, setApiData] = useState([]);
  const [price, setPrice] = useState('');

  const id = props.match.params.id;

  useEffect(() => {
    api.get('/v1/api/product?id=' + id).then(response => {
      setApiData(response.data);
      if (response.data.price !== null) {
        setPrice(
          <p className="product__price">R$ {response.data.price}
            <span className="product__price--span"> à vista</span></p>
        )
      }
    });
  }, [])
  return (
    <>
      <header>
        <Header />
      </header>
      <main>
        <span className="product__nav">
          <a className="product__link--back" href="/produtos">Voltar para produtos</a>
          <a className="product__link" href={'/produtos?type=' + apiData.type}> {apiData.type}</a> {">"}
          <a className="product__link" href={'/produtos?brand=' + apiData.brand}> {apiData.brand}</a> {">"}
          <a className="product__link" href={'/produtos?product=' + apiData.product}> {apiData.name}</a>
        </span>
        <section className="product">
          <div className="product__img">
            <div className="product__img--img">
              <InnerImageZoom
                zoomPreload={true}
                zoomType="hover"
                src={process.env.PUBLIC_URL + '/uploads/' + apiData.photo}
                zoomSrc={process.env.PUBLIC_URL + '/uploads/' + apiData.photo} />
            </div>
          </div>
          <div className="product__props">
            <div>
              <h1 className="product__name">{apiData.name}</h1>
              {price}
            </div>
            <div className="product__desc">
              <h2 className="product__desc--title">Descrição</h2>
              <p className="product__desc--text">{ReactHtmlParser(apiData.description)}</p>
            </div>
          </div>
        </section>
        <Offers />
        <Budget />
        <Address />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default Product;