import React, { Component } from 'react';
import { ErrorMessage, Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import api from '../../components/services';

import { history } from '../../history';

import './Login.css';

class Login extends Component {
  state = {
    errorMensage: ''
  }
  render() {
    const handleSubmit = values => {
      api.post('/v1/api/auth', values)
        .then(resp => {
          const { data } = resp;
          if (data) {
            sessionStorage.setItem('app-token', data.result);
            sessionStorage.setItem('name', data.firstName)
            history.push('/admin');
          }
        })
        .catch(() => {
          this.setState({errorMensage: 'E-mail ou senha invalida.'});
        });
    }

    const validations = yup.object().shape({
      email: yup.string()
        .email('E-mail inválido')
        .required('E-mail é um campo obrigatório'),
      password: yup.string()
        .min(8, 'A senha deve conter no minimo 8 caracteres')
        .required('Senha é um campo obrigatório')
    });

    return (
      <>
        <h1 className="title">Acesso Restrito</h1>
        <p className="center admin__group__error">{this.state.errorMensage}</p>
        <Formik
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          <Form className="admin">
            <div className="admin__group">
              <Field
                name="email"
                className="admin__group__field"
                placeholder="E-mail"
              />
              <ErrorMessage
                component="span"
                name="email"
                className="admin__group__error"
              ></ErrorMessage>
            </div>
            <div className="admin__group">
              <Field
                name="password"
                className="admin__group__field"
                type="password"
                placeholder="Senha"
              />
              <ErrorMessage
                component="span"
                name="password"
                className="admin__group__error"
              ></ErrorMessage>
            </div>
            <button className="admin__btn" type="submit">Login</button>
          </Form>
        </Formik>
      </>
    );
  }
}

export default Login;