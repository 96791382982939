import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';

import './Budget.css';

const Budget = () => {

  const handleSubmit = values => {
    var msg = values.msg.replace(/\n/g, "%0A");
    msg = msg.replace(/\s/g, "%20");

    const name = values.name.replace(/\s/g, "%20");
    const phone = values.phone.replace(/\s/g, "%20");

    const mensage = ('Nome: ' + name + "%0ATelefone: " + phone + "%0A" + msg)

    const whatsapp = ("https://wa.me/556484389596?text=" + mensage);
    window.open(whatsapp, '_blank');
  }

  const validations = yup.object().shape({
    name: yup.string()
      .required('Nome é obrigatório'),
    phone: yup.string()
      .required('Telefone é obrigatório'),
    msg: yup.string()
      .required('Mensagem é obrigatória'),
  });

  return (

    <section>
      <h2 className="title" >Solicite um orçamento&nbsp;...</h2>
      <div className="center">
        <Formik
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          <Form className="budget">
            <div>
              <Field
                name="name"
                className="budget__input"
                placeholder="Seu nome"
              />
              <ErrorMessage
                component="span"
                name="name"
                className="budget__input__error"
              ></ErrorMessage>
            </div>
            <div>
              <Field
                name="phone"
                className="budget__input"
                placeholder="Telefone de contato"
                type="tel"
              />
              <ErrorMessage
                component="span"
                name="phone"
                className="budget__input__error"
              ></ErrorMessage>
            </div>
            <div>
              <Field
                name="msg"
                className="budget__txt"
                placeholder="Insira sua mensagem"
                as="textarea"
              />
              <ErrorMessage
                component="span"
                name="msg"
                className="budget__input__error"
              ></ErrorMessage>
            </div>
            <button className="budget__btn" type="submit">ENVIAR</button>
          </Form>
        </Formik>
      </div>
    </section>
  );

}

export default Budget;