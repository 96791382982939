import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import ReactHtmlParser from 'react-html-parser';
import * as yup from 'yup';
import api from '../../components/services';

import AdminHeader from '../../components/admheader';

import { history } from '../../history';

const Edit = props => {

  const [apiData, setApiData] = useState([]);
  const [escritorio, setEscritorio] = useState(<option value="Escritório">Escritório</option>);
  const [informatica, setInformatica] = useState(<option value="Informática">Informática</option>);
  const [diversos, setDiversos] = useState(<option value="Diversos">Diversos</option>);
  const [textarea, setTextarea] = useState("");
  const [alterPrice, setAlterPrice] = useState(false);

  const handleClick = () => {
    history.push('/login');
    sessionStorage.removeItem('app-token');
    sessionStorage.removeItem('name');
  }

  const handlePrice = () => {
    setAlterPrice(true);
  }

  const handleSubmit = values => {
    if (values.offer === undefined) {
      values.offer = apiData.offer
    } else {
      if (values.offer.length > 0) {
        values.offer = "true"
      } else {
        values.offer = "false"
      }
    }

    if (values.name === undefined) {
      values.name = apiData.name
    }
    if (alterPrice === false) {
      values.price = apiData.price
    } if (values.price === undefined && alterPrice === true) {
      values.price = null
    }
    if (values.description === undefined) {
      values.description = apiData.description
    }
    if (values.type === undefined) {
      values.type = apiData.type
    }
    if (values.product === undefined) {
      values.product = apiData.product
    }
    if (values.brand === undefined) {
      values.brand = apiData.brand
    }

    values.appToken = sessionStorage.getItem('app-token');

    api.post('/api/modify-prod/product/' + id, values)
      .then(() => {
        alert('Edições realizadas com sucesso!')
      })
  };

  const id = props.match.params.id;

  const validations = yup.object().shape({
    name: yup.string()
      .required('Nome é obrigatório'),
    price: yup.number().transform((_, value) => {
      if (value.includes('.')) {
        return null;
      }
      return +value.replace(/,/, '.');
    })
      .positive()
      .required('Preço é obrigatório'),
    description: yup.string()
      .required('Descrição é obrigatório'),
    type: yup.string()
      .required('Categoria é obrigatório'),
    product: yup.string()
      .required('Produto é obrigatório'),
    brand: yup.string()
      .required('Marca é obrigatório')
  });

  useEffect(() => {
    api.get('/v1/api/product?id=' + id).then(response => {
      setApiData(response.data);
      const text = response.data.description;
      setTextarea(text.replace(/<br ?\/?>/g, "&#13;"))

      if (response.data.offer === "true") {
        setChecked(
          <input
            type="checkbox"
            name="offer"
            value="True"
            onClick={formik.handleChange}
            className="admin__check"
            defaultChecked
          />
        );

      }
    });
  }, []);

  const handleOption = () => {
    if (apiData.type === "Escritório") {
      setEscritorio("")
    }
    if (apiData.type === "Informática") {
      setInformatica("")
    }
    if (apiData.type === "Diversos") {
      setDiversos("")
    }
  }

  const formik = useFormik({
    initialValues: {},
    onSubmit: values => {
      handleSubmit(values)
    },
  });

  const [checked, setChecked] = useState(
    <input
      type="checkbox"
      name="offer"
      value="True"
      onClick={formik.handleChange}
      className="admin__check"
    />);

  return (
    <>
      <header>
        <AdminHeader />
      </header>
      <main>
        <div className="admin">
          <div className="card__img admin__group__img">
            <img src={process.env.PUBLIC_URL + '/uploads/' + apiData.photo} alt="" className="card__img--img" />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <label className="admin__group">
              <input
                id="name"
                name="name"
                className="admin__group__field"
                type="text"
                onChange={formik.handleChange}
                defaultValue={apiData.name}
              />
            </label>
            <label className="admin__group">
              <input
                id="price"
                name="price"
                className="admin__group__field admin__group__field--price"
                type="text"
                onChange={formik.handleChange}
                onFocus={handlePrice}
                defaultValue={apiData.price}
              />
            </label>
            <label className="admin__group">
              <textarea
                id="description"
                name="description"
                className="admin__group__field admin__group__field--textarea"
                onChange={formik.handleChange}
                defaultValue={ReactHtmlParser(textarea)}
              />
            </label>
            <label className="admin__group">
              <select
                id="type"
                name="type"
                className="admin__group__field admin__group__field--select"
                onFocus={handleOption}
                onChange={formik.handleChange}
              >
                <option value="">Categoria</option>
                <option value={apiData.type} selected>{apiData.type}</option>
                {escritorio}
                {informatica}
                {diversos}
              </select>
            </label>
            <label className="admin__group">
              <input
                id="product"
                name="product"
                className="admin__group__field"
                type="text"
                onChange={formik.handleChange}
                defaultValue={apiData.product}
              />
            </label>
            <label className="admin__group">
              <input
                id="brand"
                name="brand"
                className="admin__group__field"
                type="text"
                onChange={formik.handleChange}
                defaultValue={apiData.brand}
              />
            </label>
            <label className="admin__checkbox">
              {checked}
              <span className="admin__checkmark"></span>&nbsp; Oferta
            </label>
            <button className="admin__btn" type="submit">Salvar Edições</button>
          </form>
        </div>
      </main>
    </>
  );
}

export default Edit;