import React, { Component } from 'react';

import logo from './../../assets/img/logo-white.png';
import whatsapp from '../../assets/img/icon/whatsapp-white.png';
import instagram from '../../assets/img/icon/instagram-white.png';
import facebook from '../../assets/img/icon/facebook-white.png';

import './Footer.css';

class Footer extends Component {
  state = {}
  render() {
    return (
      <>
        <div className="footer">
          <h1 className="header__logo">
            <a href="/">
              <img src={logo} alt="Logo" className="header__logo__img" />
            </a>
          </h1>

          <nav className="footer__nav">
            <ul className="footer__list">
              <li>
                <a className="footer__list__item" href="/">HOME</a>
              </li>
              <li>
                <a className="footer__list__item" href="/produtos">PRODUTOS</a>
              </li>
              <li>
                <a className="footer__list__item" href="/servicos">SERVIÇOS</a>
              </li>
              <li>
                <a className="footer__list__item" href="https://tektelecom.com.br/" target="_blank">INTERNET</a>
              </li>
              <li>
                <a className="footer__list__item" href="/sobre">SOBRE</a>
              </li>
              <li>
                <a className="footer__list__item" href="#contato">CONTATO</a>
              </li>
            </ul>
          </nav>

          <div className="footer__social">
            <h2 className="footer__title">Redes Sociais</h2>
            <a href="https://wa.me/556484389596" target="_blank" rel="noreferrer">
              <img src={whatsapp} className="icon" alt="whatsapp"/></a>
            <a href="https://www.instagram.com/tek.informatica/" target="_blank" rel="noreferrer">
              <img src={instagram} className="icon" alt="instagram"/></a>
            <a href="https://www.facebook.com/pages/category/Computer-Store/Tek-Inform%C3%A1tica-1000167803504617/" target="_blank" rel="noreferrer">
              <img src={facebook} className="icon" alt="facebook"/></a>
          </div>

        </div>
        <span className="footer__copyright">Copyright 2021. Tek Informática - Todos os direitos reservador.</span>
      </>
    );
  }
}

export default Footer;